import React, { useEffect } from "react";
import './App.css'
import LoaderComponent from "./components/Loader/loader";
import Header from './components/Header/header'
import Footer from './components/Footer/footer'
import GlobalStyleComponent from './components/globalStyle'
import Routes from './Routes';
import { BrowserRouter as Router } from "react-router-dom";
import { ApolloProvider } from '@apollo/client';
import client from './helpers/wpHelper';

function App() {
  useEffect(() => {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty("--vh", `${vh}px`)

    const handleResize = () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty("--vh", `${vh}px`)
    }

    window.addEventListener("resize", handleResize)

    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return (
    <ApolloProvider client={client}>
      <Router>
        <GlobalStyleComponent/>
        <LoaderComponent />
        <Header/>
        <Routes/>
        <Footer/>
      </Router>
    </ApolloProvider>
  )
}

export default App
